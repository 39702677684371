var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articles-slide 2xl:container 2xl:my-20 relative flex flex-col"},[_c('ArrowBtnNav',{staticClass:"absolute -top-4 lg:top-0 xxl:-top-4 right-4 sm:right-16 lg:right-0 mt-8 sm:mr-8 z-10",attrs:{"right-arrow-disabled":_vm.isLast,"left-arrow-disabled":_vm.isFirst},on:{"onLeftClick":_vm.prevSlide,"onRightClick":_vm.nextSlide}}),_vm._v(" "),(_vm.articleRows.length)?_c('CardSlider',{ref:"slider",staticClass:"lg:overflow-x-hidden",attrs:{"slide-gap":0,"show-arrows":false,"fullwidth-slides":true,"breakpoints":_vm.breakpoints},on:{"change":_vm.setActiveSlide}},_vm._l((_vm.articleRows),function({ imageUrl, title },idx){return _c('div',{key:idx,staticClass:"article-wrapper relative lg:mr-56"},[_c('div',{staticClass:"lg:mt-8 lg:ml-8 btn-pill-black hover:bg-blackly hover:text-white hover:border-white absolute top-0 left-0 z-10 mt-4 ml-4 fluid-base leading-relaxed font-light uppercase border-none"},[_vm._v("\n        "+_vm._s(_vm.$t('featured'))+"\n      ")]),_vm._v(" "),_c('FixedAspect',{attrs:{"ratio":2 / 3}},[(imageUrl)?_c('CldnImg',{staticClass:"max-w-none absolute inset-0 z-0 block object-cover w-full h-full",attrs:{"src":imageUrl,"sizes":"(min-width: 1440px) 1104px, (min-width: 1024) 90vw, 100vw","crop":"fill","ratio":2 / 3,"alt":title}}):_vm._e()],1)],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lg:items-center lg:flex lg:flex-row lg:absolute lg:inset-0 lg:justify-end -mt-16 lg:mt-0 pointer-events-none"},[_c('div',{staticClass:"bg-blackly lg:px-10 lg:py-20 lg:w-5/12 z-10 p-6 pb-12 text-white",staticStyle:{"min-height":"5rem"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in","duration":150}},_vm._l((_vm.articleRows),function({ title, teaserText, topic, category, readTime, slug },idx){return (idx === _vm.current)?_c('div',{key:idx,staticClass:"inner pointer-events-auto"},[_c('div',{staticClass:"flex flex-row items-center space-x-4"},[(topic)?_c('TagBtn',{attrs:{"to":{
                name: `knowledge-index-topic___${_vm.$i18n.locale}`,
                params: { topic: topic.slug },
              }}},[_vm._v("\n              "+_vm._s(topic.title)+"\n            ")]):_vm._e(),_vm._v(" "),(category)?_c('TagBtn',{attrs:{"to":{
                name: `knowledge-index-category-category___${_vm.$i18n.locale}`,
                params: { category: category.slug },
              },"secondary":""}},[_vm._v("\n              "+_vm._s(category.title)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fluid-sm leading-tightest font-medium"},[_vm._v(_vm._s(_vm.$t('readTime', { min: readTime })))])],1),_vm._v(" "),_c('div',{staticClass:"mt-4 fluid-xl leading-tight font-medium"},[_vm._v(_vm._s(title))]),_vm._v(" "),_c('div',{staticClass:"text-copy mt-6",domProps:{"innerHTML":_vm._s(teaserText)}}),_vm._v(" "),_c('LinkField',{staticClass:"btn-pill-black inline-block mt-8",attrs:{"to":{ name: `article-slug___${_vm.$i18n.locale}`, params: { slug: slug } },"type-name":"FeaturedArticlesSlide","tracking-name":title}},[_vm._v(_vm._s(_vm.$t('readMore')))])],1):_vm._e()}),0)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }