<template>
  <div class="articles-slide 2xl:container 2xl:my-20 relative flex flex-col">
    <ArrowBtnNav
      class="absolute -top-4 lg:top-0 xxl:-top-4 right-4 sm:right-16 lg:right-0 mt-8 sm:mr-8 z-10"
      :right-arrow-disabled="isLast"
      :left-arrow-disabled="isFirst"
      @onLeftClick="prevSlide"
      @onRightClick="nextSlide"
    />
    <CardSlider
      v-if="articleRows.length"
      ref="slider"
      class="lg:overflow-x-hidden"
      :slide-gap="0"
      :show-arrows="false"
      :fullwidth-slides="true"
      :breakpoints="breakpoints"
      @change="setActiveSlide"
    >
      <div v-for="({ imageUrl, title }, idx) in articleRows" :key="idx" class="article-wrapper relative lg:mr-56">
        <div
          class="lg:mt-8 lg:ml-8 btn-pill-black hover:bg-blackly hover:text-white hover:border-white absolute top-0 left-0 z-10 mt-4 ml-4 fluid-base leading-relaxed font-light uppercase border-none"
        >
          {{ $t('featured') }}
        </div>
        <FixedAspect :ratio="2 / 3">
          <CldnImg
            v-if="imageUrl"
            :src="imageUrl"
            sizes="(min-width: 1440px) 1104px, (min-width: 1024) 90vw, 100vw"
            crop="fill"
            :ratio="2 / 3"
            class="max-w-none absolute inset-0 z-0 block object-cover w-full h-full"
            :alt="title"
          />
        </FixedAspect>
      </div>
    </CardSlider>
    <div
      class="lg:items-center lg:flex lg:flex-row lg:absolute lg:inset-0 lg:justify-end -mt-16 lg:mt-0 pointer-events-none"
    >
      <div class="bg-blackly lg:px-10 lg:py-20 lg:w-5/12 z-10 p-6 pb-12 text-white" style="min-height: 5rem">
        <transition name="fade" mode="out-in" :duration="150">
          <!-- eslint-disable vue/no-use-v-if-with-v-for-->
          <div
            v-for="({ title, teaserText, topic, category, readTime, slug }, idx) in articleRows"
            v-if="idx === current"
            :key="idx"
            class="inner pointer-events-auto"
          >
            <!-- eslint-enable vue/no-use-v-if-with-v-for-->
            <div class="flex flex-row items-center space-x-4">
              <TagBtn
                v-if="topic"
                :to="{
                  name: `knowledge-index-topic___${$i18n.locale}`,
                  params: { topic: topic.slug },
                }"
              >
                {{ topic.title }}
              </TagBtn>
              <TagBtn
                v-if="category"
                :to="{
                  name: `knowledge-index-category-category___${$i18n.locale}`,
                  params: { category: category.slug },
                }"
                secondary
              >
                {{ category.title }}
              </TagBtn>
              <div class="fluid-sm leading-tightest font-medium">{{ $t('readTime', { min: readTime }) }}</div>
            </div>
            <div class="mt-4 fluid-xl leading-tight font-medium">{{ title }}</div>
            <div class="text-copy mt-6" v-html="teaserText"></div>
            <LinkField
              :to="{ name: `article-slug___${$i18n.locale}`, params: { slug: slug } }"
              class="btn-pill-black inline-block mt-8"
              type-name="FeaturedArticlesSlide"
              :tracking-name="title"
              >{{ $t('readMore') }}</LinkField
            >
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import FixedAspect from '@/components/common/FixedAspect';
import TagBtn from '@/components/common/TagBtn';
import CldnImg from '@/components/CldnImg';
import ArrowBtnNav from '@/components/common/ArrowBtnNav';
import get from 'lodash/get';
import CardSlider from '@/components/common/CardSlider';
import LinkField from '@/components/common/LinkField';

export default {
  name: 'FeaturedArticlesSlide',
  components: {
    LinkField,
    CardSlider,
    TagBtn,
    CldnImg,
    FixedAspect,
    ArrowBtnNav,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      state: {
        currentSlide: 0,
        isFirst: false,
        isLast: false,
      },
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
    };
  },
  computed: {
    /**
     * merges nested articles article structure to array of objects
     * filters rows with article missing
     * returns only rows with article and fulfilled imageUrl
     */
    articleRows() {
      return this.articles.reduce((acc, curr) => {
        const article = get(curr, 'article[0]');
        if (!article) return acc;
        const imageUrl = get(curr, 'image[0].url') || get(article, 'image[0].url');
        if (!imageUrl) return acc;

        const { title, slug, readTime } = article;

        const topic = get(article, 'topics[0]');
        const category = get(article, 'categories[0]');
        const teaserText = curr.teaserText;

        acc.push({ imageUrl, title, slug, readTime, teaserText, topic, category });
        return acc;
      }, []);
    },
    current() {
      return this.state.currentSlide;
    },
    isFirst() {
      return this.state.isFirst;
    },
    isLast() {
      return this.state.isLast;
    },
  },
  methods: {
    setActiveSlide(state) {
      if (typeof state !== 'object' && !state) return;
      this.state = state;
    },
    prevSlide() {
      this.$refs.slider.$emit('prev');
    },
    nextSlide() {
      this.$refs.slider.$emit('next');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity delay-300;
}
.fade-enter,
.fade-leave-active {
  @apply opacity-0;
}
.articles-slide >>> .slick-dots {
  @apply !flex;
}
</style>
