<template>
  <div class="my-l container p-0">
    <h2 v-if="mainHeading" class="fluid-xl leading-tight font-medium my-5" data-test-id="main-heading">
      {{ mainHeading }}
    </h2>
    <div class="sm:flex-row relative flex flex-col" :class="imageRight ? 'sm:flex-row-reverse' : ''">
      <FixedAspect :ratio="2 / 3" class="sm:hidden w-full">
        <CldnImg v-if="imageSrc" :src="imageSrc" width="720" :ratio="2 / 3" crop="fill" :alt="alt" sizes="90vw" />
      </FixedAspect>
      <div class="sm:block hidden w-1/2">
        <CldnImg
          v-if="imageSrc"
          :src="imageSrc"
          :ratio="1.2"
          :alt="alt"
          crop="fill"
          class="background-image object-cover h-full"
          sizes="(min-width: 1440px) 648px, 50vw"
        />
      </div>
      <div class="sm:w-1/2 box flex flex-col items-start justify-center w-full">
        <slot>
          <h2 v-if="heading" class="fluid-3xl leading-none mt-2" data-testid="heading">{{ heading }}</h2>
        </slot>
        <div class="sm:mt-6 text-copy checkmark-bullets mt-4 fluid-lg font-light leading-normal" v-html="body"></div>
        <LinkField
          v-if="cta[0]"
          v-bind="cta[0]"
          type-name="FiftyFiftyTeaserWithBullets"
          :tracking-name="heading"
          :block-position="blockPosition"
          class="sm:mt-6 inline-block mt-4"
          :class="cta[0].type === 'email' ? 'text__bg--highlight' : 'btn-pill-black'"
          @click.native="handleClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import LinkField from '@/components/common/LinkField';
import Routine from '@/mixins/Routine';

export default {
  components: {
    FixedAspect,
    CldnImg,
    LinkField,
  },
  mixins: [Routine],
  props: {
    cta: {
      type: Array,
      default: () => [],
    },
    routine: {
      type: Object,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    mainHeading: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },
    image: {
      type: Array,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
    imageRight: {
      type: Boolean,
      default: false,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    imageSrc() {
      return this.image[0] ? this.image[0].url : null;
    },
  },
  methods: {
    handleClick() {
      if (!this.routine) return;
      const routineData = {
        event: 'gaEvent',
        event_name: 'routine_select',
        routine_select: {
          routine_name: this.routine.title,
          sport: this.parseExerciseProperties(this.routine.sports),
          training_goal: this.parseExerciseProperties(this.routine.trainingGoals),
          difficulty: this.parseExerciseProperties(this.routine.difficulty),
          routine_duration: this.routine.duration,
          event_category: 'Routine',
          event_action: 'Select',
          event_label: this.routine.title,
        },
      };
      this.$_gtm.push(routineData);
    },
  },
};
</script>

<style scoped>
.box {
  @apply py-12 xl:px-24;
}
</style>
